<template>
  <div class="v-stack h-stretch" v-if="loan">
    <div class="card light">
      <div class="content h-stretch">
        <form class="gap-3 h-stretch">
          <label class="text-left">Left To Pay:</label>
          <div class="text-left">
            {{ format.currency(loanObject.leftToPay(loan)) }} kč
          </div>
          <label class="text-left required">Date:</label>
          <InputDatePicker v-model="date"></InputDatePicker>
          <label class="text-left required">Amount:</label>
          <input v-model="amount" type="number" />
          <div class="h-stack h-end">
            <button class="submit" @click.prevent="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import utils from "@/utils.js";
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  props: ["id"],
  components: {
    InputDatePicker,
  },
  data() {
    return {
      loan: null,
      loanObject: utils.loanObject,
      date: moment().toISOString(),
      amount: 0,
      description: "Loan Payment",
      format: utils.format,
    };
  },
  methods: {
    ...mapActions(["addOneTimePay", "getLoan"]),
    submit() {
      if (this.amount > 0) {
        this.addOneTimePay({
          user: this.$store.state.id,
          amount: -this.amount,
          fine: false,
          date: this.date,
          description: this.description,
          loan: this.id,
        })
          .then(() => {
            this.$router.go(-1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    this.getLoan(this.id)
      .then((loan) => {
        this.loan = loan;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
